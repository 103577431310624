import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import { CircularProgress } from '@material-ui/core';

import formatCurrency from 'utils/formatCurrency';
import { PropostaVersao } from 'models/PropostaVersao';
import { useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import { FaCalculator } from 'react-icons/fa';
import { Th, Td } from './styles';
import { PropostaContext } from '../..';

registerLocale('pt-BR', ptBr);

interface Resultados {
  propostaVersao: PropostaVersao;
  apenasSintetica: boolean;
}

interface ResultadoSintetica {
  conta: string;
  valor: number;
  percentualROL: number;
  limitar: boolean;
}

interface ResultadoAnalitica {
  item: string;
  valor: number;
  percentualROL: number;
  percentualROB: number;
  limitar: boolean;
}

interface ResultadoVisaoPorAtividade {
  codigoAtividade: string;
  descricaoAtividade: string;
  bdiTotal: number;
  precoBruto: number;
  precoLiquido: number;
  custoMateriais: number;
  percentualROL: number;
  demaisCV: number;
  margemCont: number;
  percentualMargemROL: number;
  abcFat: number;
  isTotalRow: boolean;
}

interface iPropostaVersaoParcelas extends PropostaVersaoParcelas {
  dias?: number;
  calculo?: number;
}

interface iAcessoPrograma {
  programa: number;
  acessoLimitado: boolean;
  temAcesso: boolean;
}

// 80 Proposta - Analisador - Editar Data Embarque
// 81 Proposta - Analisador - Editar Juros
// 82 Proposta - Analisador - Editar Dias Data Juros
// 83 Proposta - Analisador - Visualização completa de tabelas

export default function Resultados({
  propostaVersao,
  apenasSintetica,
}: Resultados): JSX.Element {
  const { carregaPropostaVersao } = useContext(PropostaContext);

  const [resultadoSintetica, setResultadoSintetica] = useState<
    ResultadoSintetica[]
  >([]);
  const [resultadoAnalitica, setResultadoAnalitica] = useState<
    ResultadoAnalitica[]
  >([]);
  const [resultadoAtividade, setResultadoAtividade] = useState<
    ResultadoVisaoPorAtividade[]
  >([]);
  const [isLoadingSintetica, setIsLoadingSintetica] = useState(false);
  const [isLoadingAnalitica, setIsLoadingAnalitica] = useState(false);
  const [isLoadingAtividade, setIsLoadingAtividade] = useState(false);
  const [isLoadingPrazoMedio, setIsLoadingPrazoMedio] = useState(false);
  const [recalcularCustoFinanceiro, setRecalcularCustoFinanceiro] =
    useState(false);

  const [precisaSalvar, setPrecisaSalvar] = useState(false);

  const [tabelasLimitadas, setTabelasLimitadas] = useState(false);

  const [dadosBasicosCarregados, setDadosBasicosCarregados] = useState(false);

  const [acessoProgramas, setAcessosProgramas] = useState<iAcessoPrograma[]>(
    [] as iAcessoPrograma[],
  );
  const [custoFinanceiro, setCustoFinanceiro] = useState(0);
  const [jurosMensal, setJurosMensal] = useState(0);
  const [diasSomar, setDiasSomar] = useState(0);
  const [diasAntesEmbarque, setDiasAntesEmbarque] = useState(0);
  const [diasParaEmbarque, setDiasParaEmbarque] = useState(0);
  const [PMR, setPMR] = useState(0);
  const [dataEmbarque, setDataEmbarque] = useState<any>();
  const [dataBaseJurosEmbarque, setDataBaseJurosEmbarque] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);

  const [metaDesconto, setMetaDesconto] = useState(
    propostaVersao?.desconto ?? 0,
  );
  const [metaEbitda, setMetaEbitda] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [descontoMaximo, setDescontoMaximo] = useState<number | null>(null);

  const [propostaVersaoParcelas, setPropostaVersaoParcelas] = useState<
    iPropostaVersaoParcelas[]
  >([]);

  async function buscarListaDescontos() {
    if (!propostaVersao) return;

    const response2 = await api.get(
      `/proposta-perfil-aprovacao/min-max-desconto/${propostaVersao.id}`,
    );

    setDescontoMaximo(response2.data?.descontoMaximo ?? 0);
  }

  async function carregarDadosSintetica() {
    try {
      setIsLoadingSintetica(true);
      const response = await api.get(
        `/proposta-resultado/sintetica?versaoId=${propostaVersao.id}`,
      );

      setResultadoSintetica(response.data as ResultadoSintetica[]);
    } finally {
      setIsLoadingSintetica(false);
    }
  }

  async function carregarDadosAnalitica() {
    try {
      setIsLoadingAnalitica(true);
      const response = await api.get(
        `/proposta-resultado/analitica?versaoId=${propostaVersao.id}`,
      );

      setResultadoAnalitica(response.data as ResultadoAnalitica[]);
    } finally {
      setIsLoadingAnalitica(false);
    }
  }

  async function carregarDadosAtividade() {
    try {
      setIsLoadingAtividade(true);
      const response = await api.get(
        `/proposta-resultado/atividade?versaoId=${propostaVersao.id}`,
      );

      setResultadoAtividade(response.data as ResultadoVisaoPorAtividade[]);
    } finally {
      setIsLoadingAtividade(false);
    }
  }

  useEffect(() => {
    async function carregarDados() {
      setDadosBasicosCarregados(false);
      const response = await api.get(
        `/proposta-resultado/prazo-medio?versaoId=${propostaVersao.id}`,
      );

      const vDataEmbarque = new Date(
        `${String(response.data.dataEmbarque).split('T')[0]}T10:10:10`,
      );

      vDataEmbarque.toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });

      const vDataJuros = new Date(vDataEmbarque);

      vDataJuros.setDate(
        vDataJuros.getDate() - (response.data.diasAntesEmbarque ?? 0),
      );

      const diferenca =
        new Date(vDataEmbarque).getTime() - new Date().getTime();

      let diferencaFinal = 0;

      if (diferenca > 0) diferencaFinal = diferenca / (1000 * 3600 * 24);

      const dataE = `${vDataEmbarque.getFullYear()}-${String(
        vDataEmbarque.getMonth() + 1,
      ).padStart(2, '0')}-${String(vDataEmbarque.getDate()).padStart(2, '0')}`;

      const dataJ = vDataJuros.toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });

      setPrecisaSalvar(response.data.existemDados === false ?? true);
      setDataBaseJurosEmbarque(dataJ);
      setDiasParaEmbarque(Math.ceil(diferencaFinal));
      setDataEmbarque(new Date(`${dataE}T10:10:10`));
      setJurosMensal(response.data.jurosMensal ?? 0);
      setDiasAntesEmbarque(response.data.diasAntesEmbarque ?? 0);
      setDiasSomar(response.data.diasSomar ?? 0);
      setDadosBasicosCarregados(true);
    }

    carregarDados();
  }, []);

  async function getAcessoPrograma() {
    const response = await api.get('/acesso-programa/acesso-programa-lote', {
      params: {
        programas: [80, 81, 82, 83],
      },
    });

    const lista = response.data as iAcessoPrograma[];

    const acessoLimitadoTabela = !lista.find(
      item =>
        item.programa === 83 &&
        item.temAcesso === true &&
        item.acessoLimitado === false,
    );

    setTabelasLimitadas(acessoLimitadoTabela);

    setAcessosProgramas(lista);
    // setAcessoPrograma(response.data);
  }

  useEffect(() => {
    getAcessoPrograma();
  }, []);

  useEffect(() => {
    async function carregarDados() {
      const response = await api.get(
        `/proposta/versao/${propostaVersao.id}/parcelas`,
      );
      setPropostaVersaoParcelas(response.data as iPropostaVersaoParcelas[]);
    }

    carregarDados();
  }, []);

  async function calcularParcelas(dataEmb: string) {
    const parcelas = propostaVersaoParcelas;

    if (!dataEmb) return [];

    return parcelas.map((item: iPropostaVersaoParcelas) => {
      let diferencaCalcular = 0;

      if (!item.dataPrevista || !dataEmb) diferencaCalcular = 0;
      else {
        const diferenca =
          new Date(
            `${String(item.dataPrevista).split('T')[0]}T10:10:10`,
          ).getTime() - new Date(dataEmb).getTime();

        if (diferenca <= 0) diferencaCalcular = 0;
        else diferencaCalcular = diferenca / (1000 * 3600 * 24);
      }

      const diferencaFinal = Math.floor(diferencaCalcular);

      const divisorFormatado = Number(Number(item.percentual ?? 0) / 100 ?? 0);

      return Math.floor(diferencaFinal * divisorFormatado);
    });
  }

  async function carregarDadosPrazoMedio(
    paramJurosMensal: number,
    paramDiasAntesEmbarque: number,
    paramDataBase?: string | null,
  ) {
    setIsLoadingPrazoMedio(true);

    if (!paramDataBase || !paramDataBase) return;

    const parcelasCalculadas = await calcularParcelas(paramDataBase);

    const totalValorParcelas = propostaVersaoParcelas.reduce(
      (total: number, registro: any) => total + registro.valor ?? 0,
      0,
    );

    const totalSomaProduto = parcelasCalculadas.reduce(
      (tot: number, item: number) => tot + item,
      0,
    );

    const precoBruto = resultadoSintetica.find(
      item => item.conta === 'Preço Bruto',
    );

    const calculoPMR =
      totalValorParcelas === (precoBruto?.valor ?? 0) ? totalSomaProduto : 0;

    //= 1*(1+N25)^(P27/30)-1
    const calculoCustoFin =
      1 * (1 + (paramJurosMensal ?? 0) / 100) ** (calculoPMR / 30) - 1;

    console.log({
      recalcularCustoFinanceiro,
      totalValorParcelas,
      valor: precoBruto?.valor ?? 0,
      paramJurosMensal,
      calculoPMR,
      totalSomaProduto,
      calculoCustoFin,
    });
    const custoFinanceiroFinal = arredondaDecimais(calculoCustoFin * 100, 2);

    // Primeiro filtra para não usar dados que tenham ebitda 2
    const listaNova = resultadoSintetica.filter(
      item => item.conta !== 'Ebitda 2' && item.conta !== 'Custo Financeiro',
    );

    // buscar dados Ebitda
    const ebitdaOriginal = resultadoSintetica.find(
      item => item.conta === 'Ebitda',
    );

    const precoLiquido = resultadoSintetica.find(
      item => item.conta === 'Preço Líquido',
    );

    if (ebitdaOriginal) {
      const valorRob = precoBruto?.valor ?? 0;
      const valorRol = precoLiquido?.valor ?? 0;

      const custoFinanceiroPercentual = custoFinanceiroFinal;
      const custoFinanceiroValor = valorRob * (custoFinanceiroFinal / 100);

      const ebitda2Valor = (ebitdaOriginal.valor ?? 0) - custoFinanceiroValor;
      const ebitda2Percentual = (ebitda2Valor / valorRol) * 100;

      // Efetuar os cálculos para ebitda 2 e adicionar na lista
      listaNova.push({
        conta: 'Custo Financeiro',
        percentualROL: custoFinanceiroPercentual,
        valor: custoFinanceiroValor,
        limitar: true,
      });

      listaNova.push({
        conta: 'Ebitda 2',
        percentualROL: arredondaDecimais(ebitda2Percentual, 2),
        valor: ebitda2Valor,
        limitar: true,
      });

      setRecalcularCustoFinanceiro(false);
      setResultadoSintetica(listaNova);
    }

    /* console.log({
      paramDataBase,
      paramDiasAntesEmbarque,
      paramJurosMensal,
      calculoPMR,
      custoFinanceiroFinal,
    }); */
    setCustoFinanceiro(custoFinanceiroFinal ?? 0);
    setPMR(calculoPMR ?? 0);
    setJurosMensal(paramJurosMensal ?? 0);
    setDiasAntesEmbarque(paramDiasAntesEmbarque ?? 0);

    const vDataEmbarque = new Date(paramDataBase);
    const vDataJuros = new Date(paramDataBase);

    vDataJuros.setDate(vDataJuros.getDate() - (paramDiasAntesEmbarque ?? 0));

    const diferenca = new Date(vDataEmbarque).getTime() - new Date().getTime();

    let diferencaFinal = 0;

    if (diferenca > 0) diferencaFinal = diferenca / (1000 * 3600 * 24);

    const dataJuro = vDataJuros.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    setDataEmbarque(paramDataBase);
    setDiasParaEmbarque(Math.ceil(diferencaFinal));
    setDataBaseJurosEmbarque(dataJuro);
    setIsLoadingPrazoMedio(false);
  }

  function atualizarDados(propriedade: string, valor: any) {
    if (propriedade === 'dias_antes_embarque') {
      carregarDadosPrazoMedio(jurosMensal, Number(valor ?? 0), dataEmbarque);
    } else if (propriedade === 'juros_mensal') {
      carregarDadosPrazoMedio(
        Number(valor ?? 0),
        diasAntesEmbarque,
        dataEmbarque,
      );
    } else if (propriedade === 'data_embarque') {
      if (!Number.isNaN(Date.parse(valor)))
        carregarDadosPrazoMedio(jurosMensal, diasAntesEmbarque, valor);
    }
    setPrecisaSalvar(true);
  }

  async function carregarDadosGerais() {
    await carregarDadosSintetica();
    await carregarDadosAnalitica();
    await carregarDadosAtividade();
  }

  useEffect(() => {
    buscarListaDescontos();
    carregarDadosGerais();
  }, []);

  useEffect(() => {
    if (
      dadosBasicosCarregados === true &&
      propostaVersaoParcelas &&
      propostaVersaoParcelas.length > 0 &&
      resultadoSintetica &&
      resultadoSintetica.length > 0 &&
      !resultadoSintetica.find(item => item.conta === 'Ebitda 2')
    ) {
      if (jurosMensal && dataEmbarque) {
        carregarDadosPrazoMedio(jurosMensal, diasAntesEmbarque, dataEmbarque);
      }
    }
  }, [resultadoSintetica, propostaVersaoParcelas, dadosBasicosCarregados]);

  useEffect(() => {
    if (recalcularCustoFinanceiro === true) {
      if (jurosMensal && dataEmbarque) {
        carregarDadosPrazoMedio(jurosMensal, diasAntesEmbarque, dataEmbarque);
      }
    }
  }, [recalcularCustoFinanceiro]);

  async function checkAprovacaoDescontoNecessaria() {
    const descontoSalvar = Number(
      String(metaDesconto ?? '0').replaceAll(',', '.') ?? 0,
    );

    if (descontoSalvar > Number(descontoMaximo ?? 0)) {
      const valor = await Swal.fire({
        title: `Desconto precisa de aprovação`,
        html: `<div style='text-align: justify!important; text-justify: inter-word;'>
            O percentual informado (${descontoSalvar}%) excede o máximo permitido (<u>menor que</u> ${descontoMaximo}%) para o perfil.
            <br /><br />Ao prosseguir, o efetivo uso desse percentual passará por uma aprovação.
            A proposta ficará bloqueada enquanto aguarda uma resposta dos aprovadores.
            <br /><br />Você será informado por e-mail caso seja aprovado ou reprovado. Deseja prosseguir?</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, prosseguir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      });
      return { aprovacao: true, salvar: valor.isConfirmed };
    }

    return { aprovacao: false, salvar: true };
  }

  async function handleUpdateDescontoSelecionado(descontoEnviar: string) {
    const descontoSalvar = Number(descontoEnviar?.replaceAll(',', '.') ?? 0);

    Swal.fire({
      icon: 'info',
      text: `Atualizando o desconto`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    try {
      const retorno = await api.put(`/proposta/versao/${propostaVersao.id}`, {
        desconto: descontoSalvar,
      });

      return retorno.data;
    } catch (error: any) {
      let mensagem = error?.response?.data?.message;

      if (!mensagem) {
        mensagem = error?.message;
      }
      console.log(mensagem ?? 'Não foi possível salvar o desconto');
      throw error;
    }
  }

  async function processoSalvarDesconto() {
    try {
      let salvarDesconto: { aprovacao: boolean; salvar: boolean } = {
        aprovacao: false,
        salvar: false,
      };

      salvarDesconto = await checkAprovacaoDescontoNecessaria();

      // let recalcular = true;
      if (salvarDesconto.salvar) {
        await handleUpdateDescontoSelecionado(String(metaDesconto ?? 0));

        Swal.fire({
          icon: 'info',
          text: `Recalculando proposta`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        // Dispara processo para recalcular, após salvar.
        await api.post(`/proposta/versao/${propostaVersao.id}/recalcular`);
      }

      carregaPropostaVersao();
      Swal.close();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
    }
  }

  async function salvarValoresPrazoMedio() {
    const valor = await Swal.fire({
      title: `Substituir valores salvos`,
      html: `<div style='text-align: justify!important; text-justify: inter-word;'>
          As informações de Data de Embarque, Dias da Base de Juros e Juros irão sobrescrever os valores salvos anteriormente para a proposta. Deseja prosseguir?</div>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, prosseguir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    if (valor.isConfirmed) {
      // Colocar o processo para salvar
      setIsSaving(true);

      /* console.log({
        propostaVersaoId: propostaVersao.id,
        jurosMensal,
        diasAntesEmbarque,
        dataEmbarque,
        custoFinanceiro,
        PMR,
      }); */

      await api.put(`/proposta-resultado/prazo-medio`, {
        propostaVersaoId: propostaVersao.id,
        jurosMensal,
        diasAntesEmbarque,
        dataEmbarque,
        custoFinanceiro,
        PMR,
      });

      toast.success('Informações salvas com sucesso');
      setIsSaving(false);
      setPrecisaSalvar(false);

      if (metaDesconto !== (propostaVersao?.desconto ?? 0)) {
        await processoSalvarDesconto();
      }
    }
  }

  async function handleCalcularSimulador(isEbitda: boolean) {
    setIsLoading(true);
    setIsLoadingSintetica(true);
    setRecalcularCustoFinanceiro(false);
    setIsLoadingAnalitica(true);
    setIsLoadingAtividade(true);
    const toastId = toast.loading('Calculando...');
    try {
      let descontoCalcular = metaDesconto;

      if (isEbitda) {
        // Rodar o cálculo do ebitda para obter o percentual
        const response = await api.post(`/proposta-resultado/calcular`, {
          propostaVersaoId: propostaVersao.id,
          percentual: metaEbitda ?? 0,
          tipo: 'E',
        });

        descontoCalcular = response.data.percentual ?? 0;
        setMetaDesconto(descontoCalcular);

        if (descontoCalcular === 0) {
          Swal.fire({
            title: `Ebitda`,
            html: `<div style='text-align: justify!important; text-justify: inter-word;'>
                Não foi possível encontrar um desconto que gerasse o ebitda solicitado</div>`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#d33',
          });
          return;
        }
      }
      const responseAt = await api.post(`/proposta-resultado/calcular`, {
        propostaVersaoId: propostaVersao.id,
        percentual: descontoCalcular,
        tipo: 'D',
        calculo: 'AT',
      });

      setResultadoAtividade(responseAt.data as ResultadoVisaoPorAtividade[]);

      const responseS = await api.post(`/proposta-resultado/calcular`, {
        propostaVersaoId: propostaVersao.id,
        percentual: descontoCalcular,
        tipo: 'D',
        calculo: 'S',
      });

      const dadosSint = responseS.data as ResultadoSintetica[];
      setResultadoSintetica(dadosSint);

      const responseA = await api.post(`/proposta-resultado/calcular`, {
        propostaVersaoId: propostaVersao.id,
        percentual: descontoCalcular,
        tipo: 'D',
        calculo: 'A',
      });

      setResultadoAnalitica(responseA.data as ResultadoAnalitica[]);

      // get parcelas

      const tPropostaParcelas = propostaVersaoParcelas;

      // Pega o novo valor
      const valor =
        dadosSint.find(item => item.conta === 'Preço Bruto')?.valor ?? 0;

      const parcelas = tPropostaParcelas.map(it => {
        return {
          ...it,
          valor: it.percentual
            ? (arredondaDecimais(valor) * it.percentual) / 100
            : 0,
        };
      });

      setPropostaVersaoParcelas(parcelas);

      setRecalcularCustoFinanceiro(true);

      toast.success('Cálculo concluído');
    } catch (e: any) {
      console.log(e);
    } finally {
      toast.dismiss(toastId);
      setIsLoadingAtividade(false);
      setIsLoadingAnalitica(false);
      setIsLoadingSintetica(false);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Card className="shadow mt-1 mb-1">
        <CardBody>
          {
            // {apenasSintetica === true}
          }
          <Row className="ml-1" hidden>
            <Col sm="4">
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="form-control-sm">
                    Desconto (%)
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  value={metaDesconto ?? 0}
                  type="number"
                  onChange={text => {
                    const descontoUsar = Number(text.target.value ?? 0);
                    setMetaDesconto(descontoUsar);
                  }}
                  disabled={isLoading}
                  className="form-control-sm"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    className="btn-icon btn-2"
                    type="button"
                    outline
                    size="sm"
                    onClick={() => {
                      handleCalcularSimulador(false);
                    }}
                    disabled={isLoading}
                    title="Calcular o analisador utilizando essas informações"
                  >
                    <FaCalculator />
                  </Button>
                </InputGroupAddon>
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="form-control-sm">
                    Ebitda (%)
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  value={metaEbitda ?? 0}
                  type="number"
                  onChange={text => {
                    const ebitdaUsar = Number(text.target.value ?? 0);
                    setMetaEbitda(ebitdaUsar);
                  }}
                  disabled={isLoading}
                  className="form-control-sm"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    className="btn-icon btn-2"
                    type="button"
                    outline
                    size="sm"
                    onClick={() => {
                      handleCalcularSimulador(true);
                    }}
                    disabled={isLoading}
                    title="Calcular o analisador utilizando essas informações"
                  >
                    <FaCalculator />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col sm="8">
              <Button
                size="sm"
                className="btn-icon float-right mr-0"
                color="warning"
                type="button"
                // hidden={!precisaSalvar}
                disabled={isSaving || isLoadingPrazoMedio}
                onClick={salvarValoresPrazoMedio}
              >
                <span className="btn-inner--text">Salvar Informações</span>
              </Button>
            </Col>
          </Row>

          <Row className="ml-0 mt-5">
            <Col sm="6">
              <Row className="ml-0">
                <h6 className="heading-small text-muted mb-1">
                  Visão Sintética
                </h6>
                {isLoadingSintetica && (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={10}
                  />
                )}
              </Row>
              <hr className="mt-2" />

              <Table responsive bordered>
                <thead>
                  <tr>
                    <Th>Conta</Th>
                    <Th>Valores</Th>
                    <Th hidden={tabelasLimitadas}>% / ROL</Th>
                  </tr>
                </thead>
                <tbody>
                  {resultadoSintetica
                    .filter((item: ResultadoSintetica) => {
                      if (tabelasLimitadas) return item.limitar === false;
                      return true;
                    })
                    .map((sintetica, index) => {
                      const { conta, valor, percentualROL } = sintetica;

                      let color = {};
                      if (conta.includes('Ebitda'))
                        color = {
                          backgroundColor: '#afe1fa',
                        };

                      return (
                        <tr key={index} style={{ ...color }}>
                          <Td>
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{conta}</strong>
                            </Label>
                          </Td>
                          <Td className="text-right">
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{formatCurrency(valor)}</strong>
                            </Label>
                          </Td>
                          <Td className="text-right" hidden={tabelasLimitadas}>
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{percentualROL} %</strong>
                            </Label>
                          </Td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
            {apenasSintetica === false && (
              <>
                <Col sm="6">
                  <Row className="ml-0">
                    <Col sm="12">
                      <h6 className="heading-small text-muted mb-1 ml-0">
                        Prazo Médio de Recebimento
                        {isLoadingSintetica && (
                          <CircularProgress
                            className="mt-2 ml-2"
                            color="inherit"
                            size={10}
                          />
                        )}
                      </h6>
                    </Col>
                  </Row>
                  <hr className="mt-2" />

                  <Row
                    className="mt-1"
                    hidden={
                      !acessoProgramas.find(
                        item => item.programa === 80 && item.temAcesso === true,
                      )
                    }
                  >
                    <Col sm="3" className=" text-right">
                      <Label className="form-control-label mt-3">
                        Data Embarque:
                      </Label>
                    </Col>
                    <Col sm="4">
                      <DatePicker
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                        showIcon
                        disabled={
                          !acessoProgramas.find(
                            item =>
                              item.programa === 80 &&
                              item.temAcesso === true &&
                              item.acessoLimitado === false,
                          )
                        }
                        selected={dataEmbarque}
                        onChange={event =>
                          atualizarDados('data_embarque', event)
                        }
                      />
                    </Col>
                    <Col sm="4">
                      <Label className="form-control-label mt-3">
                        {diasParaEmbarque} dias
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    className="mt-1"
                    hidden={
                      !acessoProgramas.find(
                        item => item.programa === 82 && item.temAcesso === true,
                      )
                    }
                  >
                    <Col sm="3" className=" text-right">
                      <Label className="form-control-label mt-3">
                        Data Base Juros:
                      </Label>
                    </Col>
                    <Col sm="2">
                      <Label className="form-control-label mt-3">
                        {dataBaseJurosEmbarque}
                      </Label>
                    </Col>
                    <Col sm="2">
                      <Input
                        type="number"
                        value={diasAntesEmbarque ?? 0}
                        onChange={event =>
                          atualizarDados(
                            'dias_antes_embarque',
                            event.target.value,
                          )
                        }
                        hidden={
                          !acessoProgramas.find(
                            item =>
                              item.programa === 82 &&
                              item.temAcesso === true &&
                              item.acessoLimitado === false,
                          )
                        }
                      />
                    </Col>
                    <Col sm="4">
                      <Label className="form-control-label mt-3">
                        <small>(dias antes embarque)</small>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    className="mt-1"
                    hidden={
                      !acessoProgramas.find(
                        item => item.programa === 81 && item.temAcesso === true,
                      )
                    }
                  >
                    <Col sm="3" className=" text-right" />
                    <Col sm="4">
                      <Input
                        type="number"
                        value={jurosMensal ?? 0}
                        onChange={event =>
                          atualizarDados('juros_mensal', event.target.value)
                        }
                        disabled={
                          !acessoProgramas.find(
                            item =>
                              item.programa === 81 &&
                              item.temAcesso === true &&
                              item.acessoLimitado === false,
                          )
                        }
                      />
                    </Col>
                    <Col sm="4">
                      <Label className="form-control-label mt-3">
                        <small>Juros a.m. (a partir da data base)</small>
                      </Label>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col sm="3" className=" text-right" />
                    <Col sm="4">
                      <Label className="form-control-label mt-3">
                        {`${custoFinanceiro}%`}
                      </Label>
                    </Col>
                    <Col sm="4">
                      <Label className="form-control-label mt-3">
                        <small>Custo financeiro no período</small>
                      </Label>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col sm="3" className=" text-right" />
                    <Col sm="8">
                      <Label className="form-control-label mt-3">
                        <strong>PMR:</strong> {PMR} dias da data base
                      </Label>
                    </Col>
                  </Row>

                  <Table responsive bordered className="mt-2">
                    <thead>
                      <tr>
                        <Th>Dias</Th>
                        <Th>Data</Th>
                        <Th>%</Th>
                        <Th>Valor</Th>
                        <Th>Evento</Th>
                      </tr>
                    </thead>
                    <tbody>
                      {propostaVersaoParcelas.map((parcela, index) => {
                        const {
                          id,
                          dataPrevista,
                          observacao,
                          percentual,
                          valor,
                        } = parcela;

                        const dataParcelaPrevista = `${
                          String(dataPrevista)?.split('T')[0]
                        }T21:00:00`;

                        let diferencaDias = 0;
                        if (!dataPrevista || !dataEmbarque) diferencaDias = 0;
                        else {
                          const diferenca =
                            new Date(dataPrevista).getTime() -
                            new Date(dataEmbarque).getTime();

                          if (diferenca <= 0) diferencaDias = 0;
                          else diferencaDias = diferenca / (1000 * 3600 * 24);
                        }

                        return (
                          <tr key={index}>
                            <Td className="text-right">
                              <Label className="pt-3 pr-4 text-muted">
                                <strong>{Math.ceil(diferencaDias)}</strong>
                              </Label>
                            </Td>
                            <Td className="text-right">
                              <Label className="pt-3 pr-4 text-muted">
                                <strong>
                                  {new Date(
                                    dataParcelaPrevista,
                                  ).toLocaleDateString()}
                                </strong>
                              </Label>
                            </Td>
                            <Td className="text-right">
                              <Label className="pt-3 pr-4 text-muted">
                                <strong>{percentual} %</strong>
                              </Label>
                            </Td>
                            <Td className="text-right">
                              <Label className="pt-3 pr-4 text-muted">
                                <strong>{formatCurrency(valor, 2)}</strong>
                              </Label>
                            </Td>
                            <Td>
                              <Label className="pt-3 pr-4 text-muted">
                                <p
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  {observacao}
                                </p>
                              </Label>
                            </Td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={2} />
                        <Td className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong>
                              {propostaVersaoParcelas.reduce(
                                (total: number, registro: any) =>
                                  total + registro.valor ?? 0,
                                0,
                              ) ===
                              (resultadoSintetica.find(
                                item => item.conta === 'Preço Bruto',
                              )?.valor ?? 0)
                                ? `100%`
                                : '--'}
                            </strong>
                          </Label>
                        </Td>
                        <Td className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong>
                              {formatCurrency(
                                propostaVersaoParcelas.reduce(
                                  (total: number, registro: any) =>
                                    total + registro.valor ?? 0,
                                  0,
                                ) ?? 0,
                                2,
                              )}
                            </strong>
                          </Label>
                        </Td>
                        <td />
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </>
            )}
          </Row>

          {apenasSintetica === false && (
            <>
              <Row className="ml-0 mt-5">
                <h6 className="heading-small text-muted mb-1">
                  Visão Analítica
                </h6>
                {isLoadingAnalitica && (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={10}
                  />
                )}
              </Row>
              <hr className="mt-2" />

              <Table responsive bordered>
                <thead>
                  <tr>
                    <Th>Item</Th>
                    <Th>Valores</Th>
                    <Th hidden={tabelasLimitadas}>% / ROL</Th>
                    <Th hidden={tabelasLimitadas}>% / ROB</Th>
                  </tr>
                </thead>
                <tbody>
                  {resultadoAnalitica
                    .filter((item: ResultadoAnalitica) => {
                      if (tabelasLimitadas) return item.limitar === false;
                      return true;
                    })
                    .map((analitica, index) => {
                      const { item, valor, percentualROL, percentualROB } =
                        analitica;

                      return (
                        <tr key={index}>
                          <Td>
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{item}</strong>
                            </Label>
                          </Td>
                          <Td className="text-right">
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{formatCurrency(valor)}</strong>
                            </Label>
                          </Td>
                          <Td className="text-right" hidden={tabelasLimitadas}>
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{percentualROL} %</strong>
                            </Label>
                          </Td>
                          <Td className="text-right" hidden={tabelasLimitadas}>
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{percentualROB} %</strong>
                            </Label>
                          </Td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <Row className="ml-0 mt-5">
                <h6 className="heading-small text-muted mb-1">
                  Abertura de Preço por Atividade
                </h6>
                {isLoadingAtividade && (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={10}
                  />
                )}
              </Row>
              <hr className="mt-2" />

              <Table responsive bordered>
                <thead>
                  <tr>
                    <Th>Atividade</Th>
                    <Th>Descrição Atividade</Th>
                    <Th>BDI Total</Th>
                    <Th
                      style={{ backgroundColor: '#17446B', color: '#FFFFFF' }}
                    >
                      Preço Bruto
                    </Th>
                    <Th
                      hidden={tabelasLimitadas}
                      style={{ backgroundColor: '#17446B', color: '#FFFFFF' }}
                    >
                      Preço Líquido
                    </Th>
                    <Th hidden={tabelasLimitadas}>Custo Materiais</Th>
                    <Th hidden={tabelasLimitadas}>% / ROL</Th>
                    <Th hidden={tabelasLimitadas}>Demais CVs</Th>
                    <Th
                      hidden={tabelasLimitadas}
                      style={{ backgroundColor: '#2068A9', color: '#FFFFFF' }}
                    >
                      Margem Cont
                    </Th>
                    <Th
                      hidden={tabelasLimitadas}
                      style={{ backgroundColor: '#2068A9', color: '#FFFFFF' }}
                    >
                      % MC / ROL
                    </Th>
                    <Th hidden={tabelasLimitadas}>% ABC Fat</Th>
                  </tr>
                </thead>
                <tbody>
                  {resultadoAtividade.map((atividade, index) => {
                    const {
                      codigoAtividade,
                      descricaoAtividade,
                      bdiTotal,
                      precoBruto,
                      precoLiquido,
                      custoMateriais,
                      percentualROL,
                      demaisCV,
                      margemCont,
                      percentualMargemROL,
                      abcFat,
                      isTotalRow,
                    } = atividade;

                    const fontWeight = isTotalRow ? 850 : 'normal';
                    return (
                      <tr key={index}>
                        <Td>
                          <Label className="pt-3 pr-4 text-muted">
                            <strong>{codigoAtividade}</strong>
                          </Label>
                        </Td>
                        <Td>
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight }}>
                              {descricaoAtividade}
                            </strong>
                          </Label>
                        </Td>
                        <Td className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight }}>
                              {formatCurrency(bdiTotal)}
                            </strong>
                          </Label>
                        </Td>
                        <Td
                          className="text-right"
                          style={{ backgroundColor: '#17446B' }}
                        >
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ color: '#FFFFFF', fontWeight }}>
                              {formatCurrency(precoBruto)}
                            </strong>
                          </Label>
                        </Td>
                        <Td
                          hidden={tabelasLimitadas}
                          className="text-right"
                          style={{ backgroundColor: '#17446B' }}
                        >
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ color: '#FFFFFF', fontWeight }}>
                              {formatCurrency(precoLiquido)}
                            </strong>
                          </Label>
                        </Td>
                        <Td hidden={tabelasLimitadas} className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight }}>
                              {formatCurrency(custoMateriais)}
                            </strong>
                          </Label>
                        </Td>
                        <Td hidden={tabelasLimitadas} className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight }}>
                              {percentualROL} %
                            </strong>
                          </Label>
                        </Td>
                        <Td hidden={tabelasLimitadas} className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight }}>
                              {formatCurrency(demaisCV)}
                            </strong>
                          </Label>
                        </Td>
                        <Td
                          hidden={tabelasLimitadas}
                          className="text-right"
                          style={{ backgroundColor: '#2068A9' }}
                        >
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight, color: '#FFFFFF' }}>
                              {formatCurrency(margemCont)}
                            </strong>
                          </Label>
                        </Td>
                        <Td
                          hidden={tabelasLimitadas}
                          className="text-right"
                          style={{ backgroundColor: '#2068A9' }}
                        >
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight, color: '#FFFFFF' }}>
                              {percentualMargemROL} %
                            </strong>
                          </Label>
                        </Td>
                        <Td hidden={tabelasLimitadas} className="text-right">
                          <Label className="pt-3 pr-4 text-muted">
                            <strong style={{ fontWeight }}>{abcFat} %</strong>
                          </Label>
                        </Td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
